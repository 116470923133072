import React from "react";

const TechnehireSection1 = (props) => {
    return(
        <section className="section-1">
            <div className="container">
                <div className="title-block">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-18">
                            <h1 className="h1 section-title normal-spacing">{props.mainTitle}</h1>
                            <p className="section-description">{props.mainDescription}</p>
                        </div>
                        <div className="col-lg-4"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-20">
                        <div className="image-block">
                            <img
                                src={props.image1x?.sourceUrl}
                                srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                                width={props.image1x?.width}
                                alt={props.image1x?.altText}
                            />
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </div>
        </section>
    )
}

export default TechnehireSection1;